import * as queryString from 'qs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { signoutStart } from 'src/core/session/session.actions';
import i18nConfig from 'src/i18n';
import { FALLBACK_LOCALE } from 'src/i18n/constants';
import { broadcastMessage } from 'src/middleware/message-broadcast';
import { selectCombinedRoutes } from 'src/navigation/store/navigation.selectors';
import { mapDispatchers } from 'src/utils';

import { ResetPasswordPatientComponent } from './reset-password.component';

export const dispatchers = mapDispatchers({
  goTo: path => push(path),
  signout: signoutStart,
  broadcastSignout: () => broadcastMessage(signoutStart()),
});

type ResetPasswordProps = {
  receiveMessage: any;
  goTo: (path: string) => any;
  onLoginSuccess: () => any;
  routes: any;
  signout: () => any;
  broadcastSignout: () => any;
  pwrt: string;
  lang: string;
};

export const ResetPasswordContainer = compose<ResetPasswordProps, any>(
  connect(
    createStructuredSelector({
      routes: selectCombinedRoutes,
    }),
    dispatchers,
  ),
  withProps(({ location }) => ({
    pwrt: queryString.parse(location.search, { ignoreQueryPrefix: true }).pwrt,
    lang: queryString.parse(location.search, { ignoreQueryPrefix: true }).lang || FALLBACK_LOCALE,
  })),
  withHandlers({
    receiveMessage: (props: any) => event => {
      const { goTo, routes } = props;
      if (event.data === 'gigya-reset-password') {
        goTo(routes.authentication.login);
      }
    },
  }),
  lifecycle<ResetPasswordProps, any>({
    componentDidMount() {
      const { broadcastSignout, lang, goTo, receiveMessage, routes, signout } = this.props;

      // Being called outside of the User epics as this page is navigated to directly
      // before a patient has verified their account
      i18nConfig.changeLanguage(lang);

      const addOnClickEvent = () => {
        const buttons = document.getElementsByClassName('custom-back-selector');
        Array.prototype.map.call(buttons, b =>
          b.addEventListener('click', () => {
            // eslint-disable-next-line no-restricted-globals
            if (parent) {
              goTo(routes.authentication.root);
            }
          }),
        );
      };

      window.addEventListener('message', receiveMessage);

      // @ts-ignore
      window.customOnAfterScreenLoadHandler = () => {
        addOnClickEvent();
      };
      // sign user out of account on all tabs if they hit this page
      signout();
      broadcastSignout();
    },
    componentWillUnmount() {
      const { receiveMessage } = this.props;
      window.removeEventListener('message', receiveMessage);
    },
  }),
  withRouter,
)(ResetPasswordPatientComponent);
