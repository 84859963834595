import 'core-js/es6/array';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import 'core-js/es7/array';
import 'core-js/fn/math/trunc';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/object/keys';
import 'core-js/fn/object/values';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/repeat';
import 'core-js/fn/string/starts-with';
import * as React from 'react';
import 'react-dates/initialize';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Block } from 'src/components/block';
import { LoadingRing } from 'src/components/loading-ring/loading-ring.component';
import { ProtectedRoute } from 'src/components/protected-route';
import { Consent } from 'src/consent/consent.component';
import { ModalTypes } from 'src/core/modal/modal.types';
import i18nConfig from 'src/i18n';
import { injectScript } from 'src/inject-script';
import VisitModuleVideo, {
  saveMeetingIdToSessionStorage,
  VIRTUAL_MEETINGS_ROUTE,
} from 'src/modules/visit-module/visit-module-video';
import { Navigation } from 'src/navigation/navigation.component';
import { theme, ThemeProvider } from 'src/theme';
import { ModalMap } from 'src/ui/modal/modal.constants';
import { ModalContainer } from 'src/ui/modal/modal.container';
import { AppNavigationContainer as AppNavigation } from 'src/ui/navigation-bar';
import { getDynamicConfig } from 'src/utils';

import { Switch } from 'react-router';

import './app.style';
import { AppState } from './app.types';
import { history, persistor, store } from './store/app.store';
import { doesUserAcceptedCookies, isConsentActivated } from './store/app.utils';
import { storageListener } from './store/storage';

const {
  REACT_APP_GIGYA_TOKEN,
  REACT_APP_CONSENTS_DOMAIN_ID,
  REACT_APP_DATA_DOG_IS_ENABLED,
  REACT_APP_DATA_DOG_APPLICATION_ID,
  REACT_APP_DATA_DOG_CLIENT_TOKEN,
  REACT_APP_DATA_DOG_SITE,
  REACT_APP_DATA_DOG_SERVICE,
  REACT_APP_DATA_DOG_ENV,
  REACT_APP_DATA_DOG_SAMPLERATE,
  REACT_APP_DATA_DOG_TRACKINTERACTIONS,
  REACT_APP_DATA_DOG_TRACK_SESSION_SUBDOMAINS,
  REACT_APP_DATA_DOG_REPLAY_SAMPLE_RATE,
  REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS,
  REACT_APP_VERSION
} = getDynamicConfig();

const injectGigya = ({ onLoad, onError }) => {
  injectScript({
    path: 'https://cdns.gigya.com/js/gigya.js',
    queryParams: {
      apiKey: REACT_APP_GIGYA_TOKEN,
      lang: i18nConfig.language,
    },
    onLoad,
    onError,
  });
};

const authSuccessPath = new RegExp('auth/success[?]code=[^&]*');
const redactedPath = 'auth/success?code=REDACTED';

export const appendDataDogScript = () => {
  if (
    !REACT_APP_DATA_DOG_IS_ENABLED ||
    REACT_APP_DATA_DOG_IS_ENABLED !== 'true'
  ) {
    return;
  }
  const { DD_RUM }: any = window;
  if (DD_RUM) {
    DD_RUM.init({
      applicationId: REACT_APP_DATA_DOG_APPLICATION_ID,
      clientToken: REACT_APP_DATA_DOG_CLIENT_TOKEN,
      site: REACT_APP_DATA_DOG_SITE,
      service: REACT_APP_DATA_DOG_SERVICE,
      env: REACT_APP_DATA_DOG_ENV,
      version: REACT_APP_VERSION,
      sampleRate: +REACT_APP_DATA_DOG_SAMPLERATE,
      allowedTracingOrigins: REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS
        ? [REACT_APP_DATA_DOG_ALLOW_TRACKING_ORIGINS]
        : [],
      replaySampleRate: +REACT_APP_DATA_DOG_REPLAY_SAMPLE_RATE,
      trackSessionAcrossSubdomains:
        REACT_APP_DATA_DOG_TRACK_SESSION_SUBDOMAINS === 'true',
      trackInteractions: REACT_APP_DATA_DOG_TRACKINTERACTIONS === 'true',
      silentMultipleInit: true,
      beforeSend: (event) => {
        // Remove Access Code from the event's URLs
        event.view.url = event.view.url.replace(authSuccessPath, redactedPath)
        event.view.referrer = event.view.referrer.replace(authSuccessPath, redactedPath)
        if(event.resource && event.resource.url) {
          event.resource.url = event.resource.url.replace(authSuccessPath, redactedPath)
        }
        if(event.error && event.error.resource && event.error.resource.url) {
          event.error.resource.url = event.error.resource.url.replace(authSuccessPath, redactedPath)
        }
      }
    });
  }
};

export const appendConsentScript = () => {
  const cookieScript = document.createElement('script');
  cookieScript.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
  cookieScript.setAttribute('data-language', 'en');
  cookieScript.setAttribute('type', 'text/javascript');
  cookieScript.setAttribute('charset', 'UTF-8');
  cookieScript.setAttribute('id', 'banner-2');
  cookieScript.setAttribute('data-domain-script', REACT_APP_CONSENTS_DOMAIN_ID);
  document.head.appendChild(cookieScript);
};

export const fixModalRespawningIssue = () => {
  setTimeout(() => {
    const bannerModal: any = document.getElementById('onetrust-consent-sdk');
    if (bannerModal.childNodes) {
      // prevents spawning extra modals - bug by 3d party script
      while (bannerModal.childNodes.length > 2) {
        bannerModal.removeChild(bannerModal.lastChild);
      }
    }
  }, 3000);
};

export class App extends React.Component<{}, AppState> {
  public state = {
    errored: false,
    loaded: false,
  };

  public componentDidMount() {
    appendDataDogScript();
    if (isConsentActivated()) {
      appendConsentScript();
    }
    if (!isConsentActivated() || doesUserAcceptedCookies()) {
      injectGigya({
        onLoad: () =>
          this.setState({
            loaded: true,
          }),
        onError: () =>
          this.setState({
            errored: true,
          }),
      });
    }

    window.addEventListener('storage', storageListener(store));
  }

  // function invoke reloads page on element click means App will check if constent cookie exists again
  public addButtonListener(id: string) {
    const newDocument: any = document;
    if (newDocument.getElementById(id)) {
      newDocument.getElementById(id).onclick = () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      };
    }
    if (newDocument.getElementsByClassName(id)[0]) {
      newDocument.getElementsByClassName(id)[0].onclick = () => {
        // accept button was clicked, reaload page
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      };
    }
  }

  public waitForElm(selector) {
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  public render() {
    /*
     * Add handlers for script content elements
     */
    if (isConsentActivated()) {
      window.onload = () => {
        this.waitForElm('#onetrust-banner-sdk').then(elm => {
          if (elm) {
            this.addButtonListener('accept-recommended-btn-handler');
          }
        });
        this.waitForElm('.onetrust-close-btn-handler').then(elm => {
          if (elm) {
            this.addButtonListener('save-preference-btn-handler');
          }
        });
        this.waitForElm('.onetrust-close-btn-handler').then(elm => {
          if (elm) {
            this.addButtonListener('onetrust-accept-btn-handler');
          }
        });
      };
    }
    const { loaded } = this.state;

    if (isConsentActivated() && !doesUserAcceptedCookies()) {
      return <Consent />;
    }

    return (
      // @ts-ignore
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <I18nextProvider i18n={i18nConfig}>
            <ThemeProvider theme={theme}>
              <ConnectedRouter history={history}>
                <Block id="appContainer">
                  {!loaded && (
                    <Block
                      height="100%"
                      minHeight="40rem"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <LoadingRing delay={1000} />
                    </Block>
                  )}
                  {loaded && (
                    <Switch>
                      <Route
                        path={VIRTUAL_MEETINGS_ROUTE}
                        component={() => {
                          saveMeetingIdToSessionStorage(window.location.href);
                          return <ProtectedRoute component={() => <VisitModuleVideo />} />;
                        }}
                      />
                      <Route
                        path="/"
                        component={() => (
                          <AppNavigation>
                            <Navigation />
                          </AppNavigation>
                        )}
                      />
                    </Switch>
                  )}
                  <Block id="version" display="none">
                    {process.env.REACT_APP_VERSION}
                  </Block>
                  <ModalContainer modalMap={ModalMap} modalTypes={ModalTypes} />
                </Block>
              </ConnectedRouter>
            </ThemeProvider>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    );
  }
}
